<template>
  <div class="newsdetail my-page-wrap">
    <div class="inner">
      <div class="content">
        <div class="left">
          <img src="~@/assets/imgs/left.png" alt="" />
        </div>
        <div class="center main-content">
          <div class="detail_info">
            <div class="title">{{ detail.article_title }}</div>
            <div class="sub">
              <span><i class="el-icon-time"></i>发布时间：{{dateFormat(detail.create_time)}}</span>
              <span><i class="el-icon-view"></i>浏览次数：{{ detail.read_num || 0 }}次</span>
            </div>
          </div>
          <div class="html" v-html="detail.article_content"></div>
        </div>
        <div class="right">
          <img src="~@/assets/imgs/right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from "@/utils/http"

  export default {
    data() {
      return {
        detail: {}
      }
    },

    mounted() {
      this.getDetail(this.$route.query.id)
      // (document.title = "新闻中心-燕台山酒庄官方商城")
    },

    methods: {
      getDetail(id) {
        http({
          url: "/api/article/info?article_id=" + id,
          data: {}
        }).then((res) => {
          this.detail = res.data
        })
      },
      dateFormat(timestamp) {
        // 创建一个新的Date对象，将时间戳作为参数传入
        var date = new Date(timestamp * 1000);

        // 获取年、月、日、时、分、秒
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();

        // 将月、日、小时、分钟和秒转换为两位数，如果不足两位则补零
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        // 组合年、月、日、小时、分钟和秒，以"YYYY-MM-DD HH:MM:SS"的格式输出
        var formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedTime;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .newsdetail {
    .center {
      .detail_info {
        padding-top: 40px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
        margin-bottom: 16px;
        .title {
          color: #000000;
          font-size: 36px;
          margin-bottom: 20px;
          font-weight:bolder;
        }
        .sub {
          color: #999;
          font-size: 12px;
          span {
            margin: 0 10px;
          }
        }
      }
    }
    .html {
      text-align: left;
    }
  }
</style>
